/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  httpReceiveOnlyJson,
  httpSendOnlyJson,
  httpUploadFileAndReceiveJson,
  httpUploadFileAndReceiveText
} from '@digital-magic/zod-fetch'
import { apiBaseUrlV1 } from '@constants/configuration'
import { ImageUploadResponseSchema, VehicleId } from '@api/endpoints'
import { CarDetailed, CarList } from './types'

const adminUrl = `${apiBaseUrlV1}/admin`
const carsUrl = `${adminUrl}/cars`
const carsImageUrl = `${carsUrl}/image`
const cars360ImageUrl = `${carsUrl}/image360`
const carUrl = (carId: VehicleId): string => `${carsUrl}/${carId}`

export const getCars = () =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: carsUrl,
    responseSchema: CarList
  })

export const getCar = (carId: VehicleId) =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: carUrl(carId),
    responseSchema: CarDetailed
  })

export const updateCar = (data: CarDetailed) =>
  httpSendOnlyJson({
    method: 'PUT',
    url: carUrl(data.id),
    requestSchema: CarDetailed,
    data
  })

export const uploadCarImage = (data: File) =>
  httpUploadFileAndReceiveJson({
    method: 'POST',
    url: carsImageUrl,
    responseSchema: ImageUploadResponseSchema,
    data
  })

export const uploadCar360Image = (data: File) =>
  httpUploadFileAndReceiveText({
    method: 'POST',
    url: cars360ImageUrl,
    data
  })
