import * as z from 'zod'
import {
  OrderedImageSchema,
  PriceSchema,
  UrlStringSchema,
  VehicleIdSchema,
  VehicleMakeSchema,
  VehicleModelExtraSchema,
  VehicleModelSchema
} from '@api/endpoints'

export const Car = z.object({
  id: VehicleIdSchema,
  registrationNumber: z.string().optional(),
  make: VehicleMakeSchema,
  model: VehicleModelSchema,
  modelExtra: VehicleModelExtraSchema.optional(),
  price: PriceSchema,
  discountedPrice: PriceSchema.optional(),
  isActive: z.boolean(),
  isBooked: z.boolean()
})
export type Car = z.infer<typeof Car>

export const CarList = Car.array()
export type CarList = z.infer<typeof CarList>

export const CarAttachment = OrderedImageSchema
export type CarAttachment = z.infer<typeof CarAttachment>

export const CarDetailed = z.object({
  id: VehicleIdSchema,
  videoUrl: UrlStringSchema.optional(),
  image360Url: UrlStringSchema.optional(),
  attachments: CarAttachment.array()
})
export type CarDetailed = z.infer<typeof CarDetailed>
